<template>
	<section class="section our-system fp-auto-height-responsive" id="ourSystem">
		<div class="our-system__container">
			<div class="col-lg-7 order-lg-2 our-system__img-container">
				<img class="our-system__img our-system__img--1" v-bind:class="activeProduct === 'joeypump' ? 'state-active' : ''" src="@/assets/img/prods-joeypump.png" alt="joey pump" @click="setActiveProduct('joeypump')">
				<img class="our-system__img our-system__img--2" v-bind:class="activeProduct === 'epump' ? 'state-active' : ''" src="@/assets/img/prods-epump.png" alt="epump" @click="setActiveProduct('epump')">
				<img class="our-system__img our-system__img--3" v-bind:class="activeProduct === 'bag' ? 'state-active' : ''" src="@/assets/img/prods-bag.png" alt="bag" @click="setActiveProduct('bag')">
				<img class="our-system__img our-system__img--4" v-bind:class="activeProduct === 'bagdual' ? 'state-active' : ''" src="@/assets/img/prods-bag-2.png" alt="bag" @click="setActiveProduct('bagdual')">
				<img class="our-system__img our-system__img--5" v-bind:class="activeProduct === 'cord' ? 'state-active' : ''" src="@/assets/img/prods-cord.png" alt="cord" @click="setActiveProduct('cord')">
				<img class="our-system__img our-system__img--6" v-bind:class="activeProduct === 'tubing' ? 'state-active' : ''" src="@/assets/img/wire2.png" alt="tubing" @click="setActiveProduct('tubing')">
			</div>
			<div class="col-lg-5 col-xl-4 offset-xl-1 order-lg-1">
				<div class="our-system__main-content">
					<div class="kicker">Our System</div>
					<h2 class="text-red">Explore the elements of better nutrition &amp; hydration.</h2>
					<p>Each high-quality system component is designed for ease, with programmable, automated delivery of nourishment and hydration. </p>
					<div class="our-system__kicker">Product Details</div>
					<transition name="slide-fade">
						<div v-if="activeProduct === 'joeypump'">
							<h4>Kangaroo&trade; Joey Enteral Feeding Pump</h4>
							<div class="our-system__table-wrap mt-2 mb-4">
								<table class="table table-striped table-sm">
									<caption>Kangaroo Joey Enteral Feeding Pump Product Details</caption>
									<tbody>
										<tr>
											<th id="thHistory">History</th>
											<td>72 Hours</td>
										</tr>
										<tr>
											<th id="thLanguages">Languages</th>
											<td>15 Languages</td>
										</tr>
										<tr>
											<th id="thAutoPriming">Auto-Priming</th>
											<td>30 Seconds</td>
										</tr>
										<tr>
											<th id="thAlarmOptions">Alarm Options</th>
											<td>Non-critical, adjustable alarm volume</td>
										</tr>
										<tr>
											<th id="thSizeCm">Size (cm)</th>
											<td>10.4 cm x 13 cm x 9.1 cm</td>
										</tr>
										<tr>
											<th id="thSizeIn">Size (in)</th>
											<td>4.1 in x 5.1 in x 4.6 in</td>
										</tr>
										<tr>
											<th id="thWeightKg">Weight (kg)</th>
											<td>0.77kg</td>
										</tr>
										<tr>
											<th id="thWeightLb">Weight (lb)</th>
											<td>1.7lbs</td>
										</tr>
										<tr>
											<th id="thBatteryLife">Battery Life</th>
											<td>18 Hours</td>
										</tr>
										<tr>
											<th id="thAccuracy">Accuracy</th>
											<td>+/- 7%</td>
										</tr>
										<tr>
											<th id="thPowerCord">Power Cord</th>
											<td>Detachable</td>
										</tr>
										<tr>
											<th id="thFlushRate">Flush Rate</th>
											<td>1960 ml/hr (maximum)</td>
										</tr>
										<tr>
											<th id="thFlowRate">Flow Rate</th>
											<td>1-400 ml/hr (1 ml increments)</td>
										</tr>
										<tr>
											<th id="thFlushInterval">Flush Interval</th>
											<td>1-24 hr(s) in 1 hr increments</td>
										</tr>
										<tr>
											<th id="thVoltage">Voltage</th>
											<td>Operates on 9V DC, 1.5 Amps. Use AC adapter for wall outlet usagers</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</transition>
					<transition name="slide-fade">
						<div v-if="activeProduct === 'epump'">
							<h4>Kangaroo&trade; ePump Enteral Feeding Pump</h4>
							<div class="our-system__table-wrap mt-2 mb-4">
								<table class="table table-striped table-sm">
									<caption>Kangaroo ePump Enteral Feeding Pump Product Details</caption>
									<tbody>
									<tr>
										<th id="epumpHistory">History</th>
										<td>72 Hours</td>
									</tr>
									<tr>
										<th id="epumpLanguages">Languages</th>
										<td>15 Languages</td>
									</tr>
									<tr>
										<th id="epumpAutoPriming">Auto Priming</th>
										<td>30 Seconds</td>
									</tr>
									<tr>
										<th id="epumpAlarmOptions">Alarm Options</th>
										<td>Non-critical, adjustable alarm volume</td>
									</tr>
									<tr>
										<th id="epumpSize">Size (cm)</th>
										<td>16.8 cm x 16.3 cm x 11.7 cm</td>
									</tr>
									<tr>
										<th id="epumpSizeIn">Size (in)</th>
										<td>6.6 in x 6.4 in x 4.6 in</td>
									</tr>
									<tr>
										<th id="epumpWeight">Weight (kg)</th>
										<td>1.0 kg</td>
									</tr>
									<tr>
										<th id="epumpWeightLb">Weight (lb)</th>
										<td>2.3 lbs</td>
									</tr>
									<tr>
										<th id="epumpBatterLife">Battery Life</th>
										<td>15 Hours</td>
									</tr>
									<tr>
										<th id="epumpAccuracy">Accuracy</th>
										<td>+/- 10%</td>
									</tr>
									<tr>
										<th id="epumpPowerCord">Power Cord</th>
										<td>Detachable</td>
									</tr>
									<tr>
										<th id="epumpFlushRate">Flush Rate</th>
										<td>1960 ml/hr (maximum)</td>
									</tr>
									<tr>
										<th id="epumpFlowRate">Flow Rate</th>
										<td>1-400 ml/hr (1 ml increments)</td>
									</tr>
									<tr>
										<th id="epumpFlushInterval">Flush Interval</th>
										<td>1-24 hr(s) in 1 hr increments</td>
									</tr>
									<tr>
										<th id="epumpVoltage">Voltage</th>
										<td>Operates on 9V DC, 1.5 Amps. Use AC adapter for wall outlet usages</td>
									</tr>
									</tbody>

								</table>
							</div>
						</div>
					</transition>
					<transition name="slide-fade">
						<div v-if="activeProduct === 'bag'">
							<div class="mt-2 mb-4">
								<h4>Kangaroo&trade; Flush and hydration bag</h4>
								<div class="our-system__table-wrap mt-2 mb-4">
									<table class="table table-striped table-sm">
										<caption>Kangaroo Flush and Hydration Bag Details</caption>
										<tbody>
										<tr>
											<th id="fhbCapacity">Capacity</th>
											<td>500ml, 1000ml and Spike</td>
										</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</transition>
					<transition name="slide-fade">
						<div v-if="activeProduct === 'bagdual'">
							<div class="mt-2 mb-4">
								<h4>Kangaroo&trade; Flush and hydration bag</h4>
								<div class="our-system__table-wrap mt-2 mb-4">
									<table class="table table-striped table-sm">
										<caption>Kangaroo Flush & Hydration Bag Details</caption>
										<tbody>
										<tr>
											<th id="kfhbCapcity">Capacity</th>
											<td>500ml, 1000ml and Spike</td>
										</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</transition>
					<transition name="slide-fade">
						<div v-if="activeProduct === 'cord'">
							<div class="mt-2 mb-4">
								<h4>Kangaroo&trade; Flush Tubing</h4>
								<table class="table table-striped table-sm">
									<caption>Kangaroo Flush Tubing Details</caption>
									<tbody>
									<tr>
										<th id="kftDetails">Flush Volume</th>
										<td>10-500 in 1 ml increments</td>
									</tr>
									</tbody>
								</table>
							</div>
						</div>
					</transition>
					<transition name="slide-fade">
						<div v-if="activeProduct === 'tubing'">
							<div class="mt-2 mb-4">
								<h4>Kangaroo&trade; Tubing</h4>
								<table class="table table-striped table-sm">
									<caption>Kangaroo Tubing Details</caption>
									<tbody>
									<tr>
										<th id="ktTubingLength">Length</th>
										<td>9” from pump to feeding container</td>
									</tr>
									<tr>
										<th id="ktTubingFlushVolume">Flush Volume</th>
										<td>10-500 in 1 ml increments</td>
									</tr>
									<tr>
										<th id="ktTubingFeedRate">Feed Rate</th>
										<td>1-400 in 1ml increments</td>
									</tr>
									</tbody>
								</table>
							</div>
						</div>
					</transition>
					<button class="btn btn-primary btn-lg mt-4" @click="$emit('slideToLink', $event, 7)">Upgrade to Kangaroo&trade; Systems</button>
				</div>
			</div>
		</div>
	</section>
</template>

<script>

export default {
	name: "OurSystem",
	props: ['fullpage', 'screenSize'],
	data: () => {
		return {
			activeProduct: 'epump'
		}
	},
	methods: {
		setActiveProduct(name) {
			this.activeProduct = name;
		}
	},
	updated() {

	}
}
</script>
