<template>
	<nav class="mobilenav" ref="mobilenav">
		<div class="mobilenav__bar"></div>
		<div class="mobilenav__menu" @click="$emit('sidebartoggled', $event)"><span class="icon-menu"></span></div>
	</nav>
</template>

<script>
export default {
	name: "MobileNav",
}
</script>
