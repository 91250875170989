<template>
	<section class="section our-system-mobile fp-auto-height-responsive" id="ourSystemMobile">

		<div class="our-system-mobile__container">
			<div class="kicker">Our System</div>
			<h2 class="text-red">Explore the elements of better hydration.</h2>
			<p>Each high-quality system component is designed for ease, with programmable, automated delivery of nourishment and hydration. </p>
			<div class="our-system-mobile__content-wrap">
				<div class="our-system-mobile__product our-system-mobile__product--right">
					<div class="our-system-mobile__product-img-col">
						<img class="our-system-mobile__product-img" src="@/assets/img/prods-epump.png" alt="epump">
					</div>
					<div class="our-system-mobile__product-content-col">
						<h4 class="regular">Kangaroo&trade; ePump Enteral Feeding Pump</h4>
						<div @click="toggleCharacteristics('epump')" class="our-system-mobile__link"><span class="icon-arrow-down"></span> View Characteristics</div>
					</div>
				</div>
				<div ref="epump" class="our-system-mobile__product-content our-system-mobile__table-wrap">
					<table class="table table-striped table-sm">
						<caption>Elements of Better Hydration</caption>
						<tbody>
						<tr>
							<th id="mobOurSystemHistory">History</th>
							<td>72 Hours</td>
						</tr>
						<tr>
							<th id="mobOurSystemLanguages">Languages</th>
							<td>15 Languages</td>
						</tr>
						<tr>
							<th id="mobOurSystemAutoPriming">Auto Priming</th>
							<td>30 Seconds</td>
						</tr>
						<tr>
							<th id="mobOurSystemAlarmOptions">Alarm Options</th>
							<td>Non-critical, adjustable alarm volume</td>
						</tr>
						<tr>
							<th id="mobOurSystemSize">Size (cm)</th>
							<td>16.8 cm x 16.3 cm x 11.7 cm</td>
						</tr>
						<tr>
							<th id="mobOurSystemSizeIn">Size (in)</th>
							<td>6.6 in x 6.4 in x 4.6 in</td>
						</tr>
						<tr>
							<th id="mobOurSystemWeight">Weight (kg)</th>
							<td>1.0 kg</td>
						</tr>
						<tr>
							<th id="mobOurSystemHistoryLb">Weight (lb)</th>
							<td>2.3 lbs</td>
						</tr>
						<tr>
							<th id="mobOurSystemBatteryLife">Battery Life</th>
							<td>15 Hours</td>
						</tr>
						<tr>
							<th id="mobOurSystemAccuracy">Accuracy</th>
							<td>+/- 10%</td>
						</tr>
						<tr>
							<th id="mobOurSystemPowerCord">Power Cord</th>
							<td>Detachable</td>
						</tr>
						<tr>
							<th id="mobOurSystemFlushRate">Flush Rate</th>
							<td>1960 ml/hr (maximum)</td>
						</tr>
						<tr>
							<th id="mobOurSystemFlowRate">Flow Rate</th>
							<td>1-400 ml/hr (1 ml increments)</td>
						</tr>
						<tr>
							<th id="mobOurSystemFlushInterval">Flush Interval</th>
							<td>1-24 hr(s) in 1 hr increments</td>
						</tr>
						<tr>
							<th id="mobOurSystemVoltage">Voltage</th>
							<td>Operates on 9V DC, 1.5 Amps. Use AC adapter for wall outlet usagers</td>
						</tr>
						</tbody>

					</table>
				</div>
				<div class="our-system-mobile__product our-system-mobile__product--left">
					<div class="our-system-mobile__product-img-col">
						<img class="our-system-mobile__product-img" src="@/assets/img/prods-joeypump.png" alt="joey pump">
					</div>
					<div class="our-system-mobile__product-content-col">
						<h4 class="regular">Kangaroo&trade; Joey Enteral Feeding Pump</h4>
						<div @click="toggleCharacteristics('joeypump')" class="our-system-mobile__link"><span class="icon-arrow-down"></span> View Characteristics</div>
					</div>
				</div>
				<div ref="joeypump" class="our-system-mobile__product-content our-system-mobile__table-wrap">
					<table class="table table-striped table-sm">
						<caption>Elements of Better Hydration Mobile</caption>
						<tbody>
						<tr>
							<th id="ebhBatteryLife">Battery Life</th>
							<td>18 Hours</td>
						</tr>
						<tr>
							<th id="ebhWeight">Weight</th>
							<td>1.7 lbs., with pole clamp – 2.3 lbs</td>
						</tr>
						<tr>
							<th id="ebhFeedRate">Feed Rate</th>
							<td>1-400 in 1 ml increments</td>
						</tr>
						<tr>
							<th id="ebhFlushVolumn">Flush Volume</th>
							<td>10-500 in 1 ml increments</td>
						</tr>
						<tr>
							<th id="ebhFlushInterval">Flush Interval</th>
							<td>1-24 in 1 hr intervals</td>
						</tr>
						<tr>
							<th id="ebhMemory">Memory</th>
							<td>72 hours</td>
						</tr>
						<tr>
							<th id="ebhTubingLength">Tubing length</th>
							<td>9" (From pump to feeding container)</td>
						</tr>
						<tr>
							<th id="ebhMultilingual">Multiligual Capabilities</th>
							<td>15 languages</td>
						</tr>
						</tbody>
					</table>
				</div>
				<div class="our-system-mobile__product our-system-mobile__product--right">
					<div class="our-system-mobile__product-img-col">
						<img class="our-system-mobile__product-img" src="@/assets/img/prods-bag-2.png" alt="bag">
					</div>
					<div class="our-system-mobile__product-content-col">
						<h4 class="regular">Kangaroo&trade; Feed and Flush bags</h4>
						<div @click="toggleCharacteristics('feedflush')" class="our-system-mobile__link"><span class="icon-arrow-down"></span> View Characteristics</div>
					</div>
				</div>
				<div ref="feedflush" class="our-system-mobile__product-content"><strong>Capacity:</strong> 500 ml capacity</div>
				<div class="our-system-mobile__product our-system-mobile__product--left">
					<div class="our-system-mobile__product-img-col">
						<img class="our-system-mobile__product-img" src="@/assets/img/prods-cord.png" alt="cord">
					</div>
					<div class="our-system-mobile__product-content-col">
						<h4 class="regular">Kangaroo&trade; Flush Tubing</h4>
						<div @click="toggleCharacteristics('flush')" class="our-system-mobile__link"><span class="icon-arrow-down"></span> View Characteristics</div>
					</div>
				</div>
				<div ref="flush" class="our-system-mobile__product-content"><strong>Flush Volume:</strong> 10-500 in 1 ml increments</div>
				<div class="our-system-mobile__product our-system-mobile__product--right">
					<div class="our-system-mobile__product-img-col">
						<img class="our-system-mobile__product-img" src="@/assets/img/wire2.png" alt="cord 2">
					</div>
					<div class="our-system-mobile__product-content-col">
						<h4 class="regular">Kangaroo&trade; Tubing</h4>
						<div @click="toggleCharacteristics('tubing')" class="our-system-mobile__link"><span class="icon-arrow-down"></span> View Characteristics</div>
					</div>
				</div>
				<div ref="tubing" class="our-system-mobile__product-content"><strong>Length:</strong> 9&rdquo; from pump to feeding container<br><strong>Flush Volume:</strong> 10-500 in 1 ml increments<br><strong>Feed Rate:</strong> 1-400 in 1ml increments</div>
			</div>
			<div class="text-center"><button class="btn btn-primary btn-lg mt-4" @click="$emit('slideToLink', $event, 7)">Upgrade to Kangaroo&trade; systems</button></div>
		</div>
	</section>
</template>

<script>

export default {
	name: "OurSystemMobile",
	props: ['fullpage', 'screenSize'],
	methods: {
		toggleCharacteristics(ref) {
			if(!this.$refs[`${ref}`].classList.contains('state-open')) {
				this.$refs[`${ref}`].classList.add('state-open');
			} else {
				this.$refs[`${ref}`].classList.remove('state-open');
			}

		}
	}
}
</script>
