import Vue from "vue";
import App from "./App.vue";
import VueFullPage from 'vue-fullpage.js';
import VueTippy, { TippyComponent } from "vue-tippy";
import "tippy.js/themes/translucent.css";
import './assets/scss/main.scss';

Vue.component("tippy", TippyComponent);
Vue.use(VueFullPage);
Vue.use(VueTippy);

Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
}).$mount("#app");
