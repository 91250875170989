<template>
	<div class="audience-bar"
	     v-bind:class="audienceBarState ? `state-${audienceBarState}` : ''"
	     v-click-outside="setAudienceBarState"
	     v-on:touchstart="isTouchDevice ? $emit('setAudienceBarState', 'open') : null"
	     v-on:mouseenter="screenSize > 767 ? (isTouchDevice === true ? null : $emit('setAudienceBarState', 'open')) : (isTouchDevice === true ? null : $emit('setAudienceBarState', 'collapsed'))">
		<div class="audience-bar__title" v-on:click="screenSize < 768 ? toggleStateEmit() : null">Select your care type
			<span v-if="screenSize < 768 && audience === 'acute'">: Acute Care </span>
			<span v-if="screenSize < 768 && audience === 'postacute'">: Post Acute Care </span>
			<span v-if="screenSize < 768 && audience === 'inhome'">: At-Home Care </span>
			<span class="icon-chevron-down" v-if="screenSize < 768"></span></div>
		<div class="audience-bar__button" @click="$emit('setAudience', 'acute')" v-bind:class="audience === 'acute' ? 'state-active' : ''">
			<img src="@/assets/img/acute-circle.svg" alt="Acute" class="audience-bar__icon">
			<div class="audience-bar__acute">Acute<br class="d-none d-md-inline"> Care</div>
		</div>
		<div class="audience-bar__button" @click="$emit('setAudience', 'postacute')" v-bind:class="audience === 'postacute' ? 'state-active' : ''">
			<img src="@/assets/img/post-acute-circle.svg" alt="Post Acute" class="audience-bar__icon">
			<div class="audience-bar__post-acute"><span class="text-nowrap">Post Acute</span><br class="d-none d-md-inline"> Care</div>
		</div>
		<div class="audience-bar__button" @click="$emit('setAudience', 'inhome')" v-bind:class="audience === 'inhome' ? 'state-active' : ''">
			<img src="@/assets/img/at-home-circle.svg" alt="In Home" class="audience-bar__icon">
			<div class="audience-bar__in-home" ><span class="text-nowrap">At-Home </span><br class="d-none d-md-inline"> Care</div>
		</div>
	</div>
</template>

<script>
import ClickOutside from 'vue-click-outside'

export default {
	name: "AudienceSelectionBar",
	props: ['audience', 'audienceBarState', 'screenSize'],
	directives: {
		ClickOutside
	},
	methods: {
		setAudienceBarState(){
			this.$emit('setAudienceBarState', 'collapsed');
		},
		toggleStateEmit() {
			if(this.audienceBarState === 'collapsed') {
				this.$emit('setAudienceBarState', 'open')
			} else {
				this.$emit('setAudienceBarState', 'collapsed')
			}
		},
		isTouchDevice() {
			return (('ontouchstart' in window) ||
				(navigator.maxTouchPoints > 0) ||
				(navigator.msMaxTouchPoints > 0));
		}
	},
	created(){
		if(this.screenSize < 768) {
			this.$emit('setAudienceBarState', 'collapsed');
		}
	}
}
</script>
