<template>
	<section class="section fp-section footer-section fp-auto-height">
		<div class="footer" id="footer">
			<div class="container">
				<div class="row footer__top">
					<div class="col-md-6 col-xl-5">
						<img class="footer__kangaroo-logo" src="@/assets/img/CardinalHealthKangarooWhite.svg" width="262" height="104" alt="Cardinal Health Kangaroo"/>
						<AudienceFooterMessage :audience="audience"></AudienceFooterMessage>
					</div>
					<div class="col-md-6 col-xl-6 offset-xl-1">
						<h3 class="mb-3 balance-text">Connect with the Kangaroo&trade; team</h3>
						<p>To learn more about Kangaroo™ solutions and the benefits of automated Feed/Flush technology, connect with a Kangaroo™ Representative.</p>
						<p><a href="https://ww3.cardinalhealth.com/l/104412/2023-02-10/5tvp6y?cid=SAE-PAID_LI-MED-MPMS_ND-FeedFlush-FeedFlush_Interactive_Experience-Connect_with_a_representative-FY23" target="_blank" class="btn btn-outline-white">Connect with a rep</a></p>
					</div>
				</div>
				<div class="row footer__bottom">
					<div class="col-12">
						<SecondaryFooter></SecondaryFooter>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import * as balanceText from 'balance-text';
import AudienceFooterMessage from "./AudienceFooterMessage.vue";
import SecondaryFooter from "./SecondaryFooter.vue";

export default {
	name: "Footer",
	components: {
		AudienceFooterMessage,
		SecondaryFooter
	},
	props: ['audience'],
	created() {
		balanceText();
	}
}
</script>
