<template>
	<div id="app">
		<FlushFeedApp/>
	</div>
</template>

<script>
import FlushFeedApp from "@/components/FlushFeedApp";

export default {
	name: "App",
	components: {
		FlushFeedApp
	}
};
</script>
