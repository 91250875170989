<template>
	<section class="section how-we-can-help fp-auto-height-responsive" id="expertClinicalGuidance">
		<div class="how-we-can-help__triangle z-lowest"></div>
		<div class="how-we-can-help__parent-container">
			<div class="how-we-can-help__container mb-5">
				<div class="col-lg-12 col-xl-6">
					<div class="kicker">How we can help</div>
					<h2 class="text-red balance-text">Better hydration equals better care</h2>
					<p>Meet your patients' and residents' total feed and hydration needs with Kangaroo&trade; Systems. Programmable Feed/Flush technology helps overcome many common enteral feeding challenges.</p>
					<button class="btn btn-primary btn-lg mt-4" @click="$emit('slideToLink', $event, 7)">Discover Kangaroo&trade; systems</button>
				</div>
			</div>
			<div class="swiper how-we-can-help__carousel js-solutions">
				<!-- Additional required wrapper -->
				<div class="swiper-wrapper">
					<div class="swiper-slide" v-for="item in problemsSolutions.items" :key="item.id">
						<div class="how-we-can-help__side-by-side-wrap">
							<div class="how-we-can-help__problem-col">
								<div class="how-we-can-help__problem-card">
									<div class="how-we-can-help__kicker">{{item.problemKicker}}</div>
									<div class="how-we-can-help__title" v-html="item.problem">{{item.problem}} <span class="how-we-can-help__tooltip" content="Hello" v-if="item.tooltip" v-tippy="{ placement : 'top',  arrow: true }">{{item.tooltipTrigger}}</span></div>
								</div>
							</div>
							<div class="how-we-can-help__solution-col">
								<div class="how-we-can-help__solution-card">
									<div class="how-we-can-help__kicker">{{item.solutionKicker}}</div>
									<div class="how-we-can-help__title" v-html="item.solution">{{item.solution}} <span class="how-we-can-help__tooltip" content="Hello" v-if="item.tooltip" v-tippy="{ placement : 'top',  arrow: true }">{{item.tooltipTrigger}}</span></div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- If we need pagination -->
				<div class="swiper-pagination"></div>

				<!-- If we need navigation buttons -->
				<div class="swiper-button-prev"></div>
				<div class="swiper-button-next"></div>
			</div>
		</div>
	</section>
</template>

<script>
import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';
import ProblemsSolutions from "@/assets/data/problem-solutions.json";
import tippy, {hideAll} from 'tippy.js';
import * as balanceText from 'balance-text';
Swiper.use([Navigation, Pagination, Autoplay]);

export default {
	name: "HowWeCanHelp",
	props: ['audience'],
	data: () => {
		return {
				problemsSolutions: ProblemsSolutions
		}
	},
	methods: {

		/**
		 * Setup Carousel for problems and solutions
		 */
		setupCarousel() {
			let carousels =  document.querySelectorAll('.js-solutions');
			let solutionsSliderList = [];
			carousels.forEach((carousel, index) => {
				carousel.classList.add('how-we-can-help__carousel-' + index);
				let solutionsSlider = new Swiper('.how-we-can-help__carousel-' + index, {
					slidesPerView: 1,
					autoplay: {
						delay: 9000
					},
					pagination: {
						el: ".swiper-pagination",
						type: 'bullets',
						clickable: true
					},
					navigation:  {
						nextEl: `.how-we-can-help__carousel-${index} .swiper-button-next`,
						prevEl: `.how-we-can-help__carousel-${index} .swiper-button-prev`
					},

					on: {
						init: function () {

						},
						slideChange: function () {
							hideAll();
						}
					}
				});


				solutionsSliderList.push(solutionsSlider);
				solutionsSlider.init();
			});
		}
	},
	mounted() {
		this.setupCarousel();

		tippy('[data-tippy-content]', {
			trigger: 'click'
		});
	},
	created() {
		balanceText();
	}
}
</script>
