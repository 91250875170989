<template>
	<div>
		<div class="sidenav-bar__container">
			<div class="sidenav-bar__sidebar-container">
				<div class="sidenav-bar__sticky-wrap">
					<SidenavBar
						v-click-outside="clickOutside"
						v-if="isReady"
						v-on:sidebartoggled="toggleSidebar"
						:currentSection="currentSection"
						:currentSectionTitle="currentSectionTitle"
					/>
				</div>
			</div>
			<div class="sidenav-bar__content-container">
				<Sidenav
					ref="sidenav"
					v-on:linkClicked="linkClicked"
					v-on:slideToLink="slideToLink"/>
				<LoadingScreen :loaded="loaded"/>
				<MobileNav v-if="screenSize < 992" v-on:sidebartoggled="toggleSidebar"/>
				<full-page :options="options" id="fullpage" ref="fullpage" :skip-init="true">
					<Home
						:isReady="isReady"
						:audience="audience"
						:imgSrc="imgSrc"
						:fullpage="this.$refs.fullpage"
						v-on:slideToLink="slideToLink"/>
					<WhoItsFor
						:audience="audience"
						:fullpage="this.$refs.fullpage"
						:audienceBarState="audienceBarState"
						:audienceBarStuck="audienceBarStuck"
						v-on:setAudienceBarState="setAudienceBarState"
						v-on:setAudience="setAudience"
						v-on:slideToLink="slideToLink" />
					<Features
						:audience="audience"
						:epumpSlideLoaded="epumpSlideLoaded"
						:fullpage="this.$refs.fullpage"
						v-on:slideToLink="slideToLink" />
					<HowWeCanHelp
						:audience="audience"
						:fullpage="this.$refs.fullpage"
						v-on:slideToLink="slideToLink" />
					<ExpertClinicalGuidance
						:audience="audience"
						:fullpage="this.$refs.fullpage"
						v-on:slideToLink="slideToLink" />
					<OurSystem
						v-if="screenSize > 991"
						:audience="audience"
						:fullpage="this.$refs.fullpage"
						:screenSize="screenSize"
						v-on:slideToLink="slideToLink"
					/>
					<OurSystemMobile
						v-if="screenSize < 992"
						:audience="audience"
						:screenSize="screenSize"
						:fullpage="this.$refs.fullpage"
						v-on:slideToLink="slideToLink"
					/>
					<Footer
						:audience="audience"
						:fullpage="this.$refs.fullpage"/>
				</full-page>
			</div>
		</div>
		<AudienceSelectionBar
			:audienceBarState="audienceBarState"
			:audience="audience"
			:screenSize="screenSize"
			v-on:setAudience="setAudience"
			v-on:setAudienceBarState="setAudienceBarState"/>
	</div>
</template>

<script>
import Sidenav from "./Sidenav.vue";
import SidenavBar from "./SidenavBar.vue";
import AudienceSelectionBar from "./AudienceSelectionBar.vue";
import ClickOutside from 'vue-click-outside'
import LoadingScreen from "./LoadingScreen.vue";
import Home from "./Home.vue";
import WhoItsFor from "./WhoItsFor.vue";
import MobileNav from "./MobileNav.vue";
import Features from "./Features.vue";
import ExpertClinicalGuidance from "./ExpertClinicalGuidance.vue";
import HowWeCanHelp from "./HowWeCanHelp.vue";
import OurSystem from "./OurSystem.vue";
import OurSystemMobile from "./OurSystemMobile.vue";
import Footer from "./Footer/Footer.vue";
import {throttle} from 'throttle-debounce';
import {hideAll} from 'tippy.js';


export default {
	name: "FlushFeedApp",
	components: {
		LoadingScreen,
		Home,
		MobileNav,
		WhoItsFor,
		AudienceSelectionBar,
		ExpertClinicalGuidance,
		HowWeCanHelp,
		OurSystem,
		OurSystemMobile,
		Sidenav,
		SidenavBar,
		Footer,
		Features
	},
	directives: {
		ClickOutside
	},
	data: function () {
		return {
			currentSection: "01",
			currentSectionTitle: "Kangaroo&trade; is always by your side",
			audience: "acute",
			audienceBarState: "open",
			epumpSlideLoaded: false,
			audienceBarStuck: false,
			isReady: false,
			imgSrc: require('@/assets/img/home-hero.png'),
			setInt: null,
			screenSize: window.innerWidth,
			loaded: false,
			options: {
				licenseKey: 'FECC2FB3-F9834F01-82690372-BB627591',
				navigation: false,
				normalScrollElements: '.our-system__table-wrap',
				responsiveWidth: 768,
				fixedElements: '.audience-bar',
				afterLoad: this.afterLoad,
				onLeave: this.onLeave,
				touchSensitivity: 7
			}
		}
	},
	methods: {
		getScreenSize() {
			return window.innerWidth;
		},
		toggleSidebar() {
			this.toggleClasses();
		},

		linkClicked(event, currSection) {
			this.currentSection = currSection;
			this.toggleClasses();
		},

		rotateHomepageImages() {
			if(this.imgSrc === require('@/assets/img/home-hero.png')) {
				this.imgSrc = require('@/assets/img/home-hero-2.png');
			} else {
				this.imgSrc = require('@/assets/img/home-hero.png');
			}
		},

		toggleClasses() {
			if (this.$refs.sidenav.$el.classList.contains('state-visible')) {
				this.$refs.sidenav.$el.classList.remove('state-visible');
			} else {
				this.$refs.sidenav.$el.classList.add('state-visible');
			}
		},

		clickOutside() {
			if(this.$refs.sidenav.$el.classList.contains('state-visible')) {
				setTimeout(() => {
					this.$refs.sidenav.$el.classList.remove('state-visible');
				}, 600);
			}
			// if(event.target.classList.contains('icon-menu')) {
			// 	return
			// } else {
			// 	this.$refs.sidenav.$el.classList.remove('state-visible');
			// }
		},

		loadedCheck() {
			return new Promise((resolve) => {
				setTimeout(() => {
					this.loaded = true;
					resolve();
				}, 2000);
			});
		},

		/**
		 * When a link is clicked run this api function
		 * that slides it down to a particular index/slide
		 */
		slideToLink(event, index) {
			this.$refs.fullpage.api.moveTo(index);
			event.preventDefault();
		},

		/**
		 * This function runs after the vue fullpage slide changes
		 * @param section
		 * @param destination
		 */

		afterLoad(section, destination) {
			this.currentSection = `0${destination.index + 1}`;

			//home screen
			if (destination.index === 0) {
				this.currentSectionTitle = "<strong>Kangaroo</strong><sup>TM</sup> is always by your side";
				this.setInt = setInterval(this.rotateHomepageImages, 5000);
			}

			if (destination.index === 1) {
				this.currentSectionTitle = "<strong>Who</strong> it's for";
			}

			if (destination.index === 2) {
				this.currentSectionTitle = "Features";
				this.epumpSlideLoaded = true;
			}

			if (destination.index === 3) {
				this.currentSectionTitle = "<strong>How</strong> we can help";
			}

			if (destination.index === 4) {
				this.currentSectionTitle = "<strong>Clinical</strong> guidance";
			}

			if (destination.index === 5) {
				this.currentSectionTitle = "<strong>Our</strong> system";
			}
		},

		onLeave(section) {
			hideAll();
			if (section.index === 0) {
				clearInterval(this.setInt);
			}
		},

		setAudience(audience) {
			this.audience = audience;
			this.audienceBarState = 'collapsed';
		},

		setAudienceBarState(state) {
			this.audienceBarState = state;
		}
	},
	created() {
		window.addEventListener('resize', throttle(200, false, () => {
			this.screenSize = this.getScreenSize();
		}));
	},

	mounted(){
		this.screenSize = this.getScreenSize();
		window.addEventListener('load', () => {
			this.loadedCheck().then(() => {
				this.isReady = true;
				document.body.classList.remove('app-fixed');
				setTimeout(() => {
					this.$refs.fullpage.init();
				}, 500);
			});
		});
	}
}
</script>
