<template>
	<section class="section whoitsfor whoitsfor__bg fp-auto-height-responsive" id="whoItsFor">
		<div class="whoitsfor__container">
			<div class="col-md-6">
				<div class="whoitsfor__hotspot-wrap">
					<img src="@/assets/img/whositforbags.png" alt="Whos It For" class="whoitsfor__hero">
					<div data-tippy-content="Flush Bag supports fewer tube occlusions." class="whoitsfor__hotspot whoitsfor__hotspot--1" v-bind:class="hotspot1Active ? 'state-hotspot-active' : ''" @click="toggleHotspot1">
						<img class="whoitsfor__hotspot-img" src="@/assets/img/hotspot.svg" alt="hotspot1" width="30" height="30">
					</div>
					<div data-tippy-content="Feed bag aids hydration to maintain fluid balance in the body and helps meet total hydration needs." class="whoitsfor__hotspot whoitsfor__hotspot--2" v-bind:class="hotspot2Active ? 'state-hotspot-active' : ''" @click="toggleHotspot2">
						<img class="whoitsfor__hotspot-img" src="@/assets/img/hotspot.svg" alt="hotspot2" width="30" height="30">
					</div>
					<div data-tippy-content="Feed and Flush technology allows for less tube clogging, promotes tube patency." class="whoitsfor__hotspot whoitsfor__hotspot--3" v-bind:class="hotspot3Active ? 'state-hotspot-active' : ''" @click="toggleHotspot3">
						<img class="whoitsfor__hotspot-img" src="@/assets/img/hotspot.svg" alt="hotspot3" width="30" height="30">
					</div>
				</div>
			</div>
			<div class="col-md-6">
				<div class="whoitsfor__copy-container">
					<div class="kicker">Who it&rsquo;s for</div>
					<h2 class="text-red balance-text">Kangaroo&trade; solutions are right where you need us</h2>
					<p>We're by your side across the care continuum, providing reliable and intuitive Feed/Flush technology.</p>
					<transition name="slide-fade">
						<div v-if="audience ==='acute'">
							<h3 class="position-relative"><img src="@/assets/img/acute.svg" class="whoitsfor__icon" alt="Acute Healthcare Icon"><span class="fontMyriadPro">Kangaroo&trade; solutions are for</span> acute care</h3>
							<p>Support enteral feeding patients with better hydration, nutrition delivery and safety.</p>
							<ul class="unordered-list">
								<li>Efficiently manage nutrition delivery through the Kangaroo&trade; solutions customizable, automated nutrition system
									<ul>
										<li>Ensures patient hydration</li>
										<li>Creates efficiencies and reduces nursing time</li>
									</ul>
								</li>
								<li>Easily program and automate flushes
									<ul>
										<li>Increases tube patency</li>
										<li>Reduces risk of cross-contamination</li>
									</ul>
								</li>
								<li>Safely achieve increased compliance with ASPEN guidelines
									<ul>
										<li>Helps to achieve compliance in nutrition goals and protocols</li>
										<li>Ensures flushing order compliance</li>
									</ul>
								</li>
							</ul>
						</div>
					</transition>
					<transition name="slide-fade">
						<div v-if="audience ==='postacute'">
							<h3 class="position-relative"><img src="@/assets/img/post-acute.svg" class="whoitsfor__icon" alt="Post Acute Icon"><span class="fontMyriadPro">Kangaroo&trade; solutions are for</span> post-acute care.</h3>
							<p>Help ensure enteral feeding residents receive safe, efficient nutrition and hydration.</p>
							<ul class="unordered-list">
								<li>Proactive measures can decrease rehospitalization rates
									<ul>
										<li>Reduce risks associated with dehydration</li>
										<li>Reduced risk of cross-contamination and increased resident safety</li>
										<li>Increase tube patency </li>
									</ul>
								</li>
								<li><div class="balance-text">Enhanced enteral feeding protocols can lead to reduction in nursing times (Pash E, Parikh N, Hashemi L. JPEN J Parenter Enteral Nutr.  2014;38(Suppl 2):58S-64S.)</div>
									<ul>
										<li>Greater efficiencies have a positive financial impact</li>
									</ul>
								</li>
								<li>72 hours of feed & flushing history helps to achieve compliance in nutrition goals
									<ul>
										<li>Helps increase compliance with ASPEN guidelines</li>
									</ul>
								</li>
							</ul>
						</div>
					</transition>
					<transition name="slide-fade">
						<div v-if="audience ==='inhome'">
							<h3 class="position-relative"><img src="@/assets/img/at-home.svg" class="whoitsfor__icon" alt="At Home Icon"><span class="fontMyriadPro">Kangaroo&trade; solutions are for</span> at-home care.</h3>
							<p>Safely administer nourishment and water delivery to help drive better patient outcomes and deliver a better at-home patient experience.</p>
							<ul class="unordered-list">
								<li>Programmable flushing helps those who can’t manually flush
									<ul>
										<li>Easy to program and use</li>
									</ul>
								</li>
								<li>Portable design gives flexibility and freedom back to patients and their families
									<ul>
										<li>Ensures patient hydration throughout the day</li>
										<li>Intuitive and compact solution</li>
									</ul>
								</li>
							</ul>
						</div>
					</transition>
					<button class="btn btn-lg btn-primary mt-4" type="button" @click="$emit('slideToLink', $event, 7)">See benefits</button>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import tippy from 'tippy.js';
import * as balanceText from 'balance-text';
export default {
	name: "WhoItsFor",
	data: () => {
		return {
			hotspot1Active: false,
			hotspot2Active: false,
			hotspot3Active: false
		}
	},
	props: ['audience', 'audienceBarState', 'fullpage'],
	methods: {
		toggleHotspot3() {
			this.hotspot3Active = !this.hotspot3Active;
		},
		toggleHotspot2() {
			this.hotspot2Active = !this.hotspot2Active;
		},
		toggleHotspot1() {
			this.hotspot1Active = !this.hotspot1Active;
		}
	},
	created() {
		balanceText();
	},
	mounted() {
		tippy('[data-tippy-content]', {
			trigger: 'click'
		});
	}
}
</script>
