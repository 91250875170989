<template>
	<section class="section features" id="features">
		<div class="z-low position-relative">
			<div class="features__container">
				<div class="col-md-3 mt-5 pt-5">
					<div class="kicker">Features</div>
					<h2 class="text-md-nowrap text-red">Get to know Kangaroo&trade; systems</h2>
					<p v-if="joeyPumpChecked">Kangaroo&trade; Joey Enteral Feeding Pump and Pump Sets: <strong>{{activeButtonComputed}}</strong>.</p>
					<p v-if="!joeyPumpChecked" class="features__mobile-min-height">Kangaroo&trade; ePump Enteral Feeding Pump and Pump Sets: <strong>{{activeButtonComputed}}</strong>.</p>
				</div>
					<div class="features__framestack">
						<div class="joeyepumpwrap" :class="joeyPumpChecked ? 'state-joey-active' : ''">
							<div class="joey" :class="joeyPumpChecked ? 'joey--visible' : ''"></div>
							<!--joey-->
							<div data-tippy-content="Intuitive user interface and large, backlit LCD display" class="features__hs features__joey-hs-ui features__joey-hs-ui--1" :class="joeyPumpChecked ? 'state-visible' : ''"><img class="features__hotspot-img" src="@/assets/img/hotspot.svg" alt="hotspot1" width="30" height="30"></div>
							<div data-tippy-content="Step-by-step prompts to guide programming and operation" class="features__hs features__joey-hs-ui features__joey-hs-ui--2" :class="joeyPumpChecked ? 'state-visible' : ''"><img class="features__hotspot-img" src="@/assets/img/hotspot.svg" alt="hotspot1" width="30" height="30"></div>
							<div data-tippy-content='"Stoplight" LED array visually indicates pump status in a bright or darkened room' class="features__hs features__joey-hs-ui features__joey-hs-ui--3" :class="joeyPumpChecked ? 'state-visible' : ''"><img class="features__hotspot-img" src="@/assets/img/hotspot.svg" alt="hotspot1" width="30" height="30"></div>

							<div data-tippy-content="Transparent top door to protect pump set" class="features__hs features__joey-hs-erg features__joey-hs-erg--1"><img class="features__hotspot-img" src="@/assets/img/hotspot.svg" alt="hotspot1" width="30" height="30"></div>
							<div data-tippy-content="Simple loading of pump sets" class="features__hs features__joey-hs-erg features__joey-hs-erg--2"><img class="features__hotspot-img" src="@/assets/img/hotspot.svg" alt="hotspot1" width="30" height="30"></div>
							<div data-tippy-content="Compact, aesthetic design with tabletop usage or IV pole mounting" class="features__hs features__joey-hs-erg features__joey-hs-erg--3"><img class="features__hotspot-img" src="@/assets/img/hotspot.svg" alt="hotspot1" width="30" height="30"></div>

							<div data-tippy-content="Audible alarm to indicate errors on pump set loading conditions" class="features__hs features__joey-hs-feat features__joey-hs-feat--1"><img class="features__hotspot-img" src="@/assets/img/hotspot.svg" alt="hotspot1" width="30" height="30"></div>
							<div data-tippy-content='Automatic Anti-Free Flow (AFF) System' class="features__hs features__joey-hs-feat features__joey-hs-feat--2"><img class="features__hotspot-img" src="@/assets/img/hotspot.svg" alt="hotspot1" width="30" height="30"></div>
							<div data-tippy-content="Continuous feed, intermittent feed and flush capability with 72 hours of previous history stored" class="features__hs features__joey-hs-feat features__joey-hs-feat--3"><img class="features__hotspot-img" src="@/assets/img/hotspot.svg" alt="hotspot1" width="30" height="30"></div>

							<div data-tippy-content="Easily Removable A/C Power Adaptor" class="features__hs features__joey-hs-serv features__joey-hs-serv--1"><img class="features__hotspot-img" src="@/assets/img/hotspot.svg" alt="hotspot1" width="30" height="30"></div>
							<div data-tippy-content="Uses replaceable, rechargeable Lithium-Ion Battery Pack for 18 hours of backup power" class="features__hs features__joey-hs-serv features__joey-hs-serv--2"><img class="features__hotspot-img" src="@/assets/img/hotspot.svg" alt="hotspot1" width="30" height="30"></div>

							<div data-tippy-content="DEHP-free" class="features__hs features__joey-hs-efps features__joey-hs-efps--1"><img class="features__hotspot-img" src="@/assets/img/hotspot.svg" alt="hotspot1" width="30" height="30"></div>
							<div data-tippy-content="Anti-free flow design" class="features__hs features__joey-hs-efps features__joey-hs-efps--2"><img class="features__hotspot-img" src="@/assets/img/hotspot.svg" alt="hotspot1" width="30" height="30"></div>
							<div data-tippy-content="ENFit&trade; design" class="features__hs features__joey-hs-efps features__joey-hs-efps--3"><img class="features__hotspot-img" src="@/assets/img/hotspot.svg" alt="hotspot1" width="30" height="30"></div>
						</div>
						<div class="features__wrap" :class="joeyPumpChecked ? 'features__wrap--joey-visible' : ''">
							<ul class="features__list">
								<li class="features__item"><a href="#" class="btn btn-outline-white active" ref="joeyUserInterface" @click="goToFrame(52, 'joey', $event)">User interface</a></li>
								<li class="features__item"><a href="#" class="btn btn-outline-white" @click="goToFrame(10, 'joey', $event)">Ergonomics</a></li>
								<li class="features__item"><a href="#" class="btn btn-outline-white" @click="goToFrame(21, 'joey', $event)">Service</a></li>
								<li class="features__item"><a href="#" class="btn btn-outline-white" @click="goToFrame(44, 'joey', $event)">Features</a></li>
								<li class="features__item"><a href="#" class="btn btn-outline-white" @click="goToFrame(48, 'joey', $event)">Enteral feed pump sets</a></li>
							</ul>
						</div>
						<div class="joeyepumpwrap" :class="!joeyPumpChecked ? 'state-epump-active' : ''">
							<div class="epump" :class="!joeyPumpChecked ? 'epump--visible' : ''"></div>
							<!--epump-->
							<div data-tippy-content="Intuitive user interface and large, backlit LCD display" class="features__hs features__epump-hs-ui features__epump-hs-ui--1" :class="!joeyPumpChecked ? 'state-visible' : ''"><img class="features__hotspot-img" src="@/assets/img/hotspot.svg" alt="hotspot1" width="30" height="30"></div>
							<div data-tippy-content="Step-by-step prompts to guide programming and operation" class="features__hs features__epump-hs-ui features__epump-hs-ui--2" :class="!joeyPumpChecked ? 'state-visible' : ''"><img class="features__hotspot-img" src="@/assets/img/hotspot.svg" alt="hotspot1" width="30" height="30"></div>
							<div data-tippy-content='"Stoplight" LED array visually indicates pump status in a bright or darkened room' class="features__hs features__epump-hs-ui features__epump-hs-ui--3" :class="!joeyPumpChecked ? 'state-visible' : ''"><img class="features__hotspot-img" src="@/assets/img/hotspot.svg" alt="hotspot1" width="30" height="30"></div>

							<div data-tippy-content="Simple loading of pump sets" class="features__hs features__epump-hs-erg features__epump-hs-erg--1"><img class="features__hotspot-img" src="@/assets/img/hotspot.svg" alt="hotspot1" width="30" height="30"></div>
							<div data-tippy-content="Transparent side door to protect pump set" class="features__hs features__epump-hs-erg features__epump-hs-erg--2"><img class="features__hotspot-img" src="@/assets/img/hotspot.svg" alt="hotspot1" width="30" height="30"></div>
							<div data-tippy-content="Compact, aesthetic design with tabletop usage or IV pole mounting" class="features__hs features__epump-hs-erg features__epump-hs-erg--3"><img class="features__hotspot-img" src="@/assets/img/hotspot.svg" alt="hotspot1" width="30" height="30"></div>

							<div data-tippy-content="Audible alarm to indicate errors on pump set loading conditions" class="features__hs features__epump-hs-feat features__epump-hs-feat--1"><img class="features__hotspot-img" src="@/assets/img/hotspot.svg" alt="hotspot1" width="30" height="30"></div>
							<div data-tippy-content="Automatic Anti-Free Flow (AFF) System" class="features__hs features__epump-hs-feat features__epump-hs-feat--2"><img class="features__hotspot-img" src="@/assets/img/hotspot.svg" alt="hotspot1" width="30" height="30"></div>
							<div data-tippy-content="Continuous feed, intermittent feed and flush capability with 72 hours of previous history stored" class="features__hs features__epump-hs-feat features__epump-hs-feat--3"><img class="features__hotspot-img" src="@/assets/img/hotspot.svg" alt="hotspot1" width="30" height="30"></div>

							<div data-tippy-content="Removable A/C Power Adaptor" class="features__hs features__epump-hs-serv features__epump-hs-serv--1"><img class="features__hotspot-img" src="@/assets/img/hotspot.svg" alt="hotspot1" width="30" height="30"></div>
							<div data-tippy-content="Uses replaceable, rechargable NiMH Battery Pack for 15 hours of backup power" class="features__hs features__epump-hs-serv features__epump-hs-serv--2"><img class="features__hotspot-img" src="@/assets/img/hotspot.svg" alt="hotspot1" width="30" height="30"></div>
							<div data-tippy-content="Serial I/O port for data retrieval" class="features__hs features__epump-hs-serv features__epump-hs-serv--3"><img class="features__hotspot-img" src="@/assets/img/hotspot.svg" alt="hotspot1" width="30" height="30"></div>

							<div data-tippy-content="DEHP-free" class="features__hs features__epump-hs-efps features__epump-hs-efps--1"><img class="features__hotspot-img" src="@/assets/img/hotspot.svg" alt="hotspot1" width="30" height="30"></div>
							<div data-tippy-content="Anti-free flow design" class="features__hs features__epump-hs-efps features__epump-hs-efps--2"><img class="features__hotspot-img" src="@/assets/img/hotspot.svg" alt="hotspot1" width="30" height="30"></div>
							<div data-tippy-content="ENFit&trade; design" class="features__hs features__epump-hs-efps features__epump-hs-efps--3"><img class="features__hotspot-img" src="@/assets/img/hotspot.svg" alt="hotspot1" width="30" height="30"></div>
						</div>
						<div class="features__wrap" :class="!joeyPumpChecked ? 'features__wrap--epump-visible' : ''">
							<ul class="features__list">
								<li class="features__item"><a href="#" class="btn btn-outline-white active" ref="epumpUserInterface" @click="goToFrame(1, 'epump', $event)">User interface</a></li>
								<li class="features__item"><a href="#" class="btn btn-outline-white" ref="epumpUserInterface2" @click="goToFrame(13,'epump', $event)">Ergonomics</a></li>
								<li class="features__item"><a href="#" class="btn btn-outline-white" ref="epumpUserInterface3" @click="goToFrame(24, 'epump', $event)">Service</a></li>
								<li class="features__item"><a href="#" class="btn btn-outline-white" ref="epumpUserInterface4" @click="goToFrame(44,'epump', $event)">Features</a></li>
								<li class="features__item"><a href="#" class="btn btn-outline-white" ref="epumpUserInterface5" @click="goToFrame(49, 'epump', $event)">Enteral feed pump sets</a></li>
							</ul>
						</div>
					</div>

				<div>

					<div class="switch-button-wrap">
						<div class="switch-button" @click="setJoeyPumpChecked">
							<input class="switch-button-checkbox" :checked="joeyPumpChecked" type="checkbox"/>
							<label class="switch-button-label" for="" ><span
								class="switch-button-label-span">ePump</span></label>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import $ from 'jquery';
// eslint-disable-next-line
import '../lib/jquery.jsmovie.min.js';
import tippy from 'tippy.js';

const epumpAnimation = {
	name: 'epump',
	//folder: '../assets/img/framestack/joeypump/',
	folder: '/framestack/epump/',
	sequence: 'kangaroo_#####.png',
	from: 0,
	to: 54,
	repeat: false,
	loadParallel: 54,
	fps: 16,
	performStop: true,
	width: 1400,
	height: 788,
	selector: '.epump'
};

const joeyPumpAnimation = {
	name: 'joey',
	folder: '/framestack/joeypump/',
	sequence: 'joey_#####.png',
	from: 0,
	to: 54,
	repeat: false,
	fps: 16,
	performStop: true,
	width: 1400,
	height: 788,
	selector: '.joey'
};

export default {
	name: "Features",
	//components: {Framestack},
	props: ['fullpage', 'epumpSlideLoaded'],
	data: () => {
		return {
			transitionName: 'fade',
			joeyPumpChecked: null,
			activeButton: 'User interface'
		}
	},
	methods: {
		setJoeyPumpChecked(){
			this.joeyPumpChecked = !this.joeyPumpChecked;
		},
		goToFrame(i, div, event) {
			event.preventDefault();
			let buttons = document.querySelectorAll('.features__list .btn');
			buttons.forEach((button) => {
				button.classList.remove('active');
			});
			event.target.classList.add('active');
			const $el = $(`.${div}`);
			$el.jsMovie('play', 1, i, false, false);
			this.activeButton = event.target.textContent;
		},
		removeAllHotspotActiveClasses() {
			let hotspots =  document.querySelectorAll('.features__hs');
			hotspots.forEach((hotspot) => {
				hotspot.classList.remove('state-visible');
			});
		},
		queryActiveHotspots(selector) {
			let hotspots =  document.querySelectorAll(`.${selector}`);
			hotspots.forEach((hotspot) => {
				hotspot.classList.add('state-visible');
			});
		}
	},
	mounted(){
		this.joeyPumpChecked = false;
		tippy('[data-tippy-content]', {
			trigger: 'click'
		});
	},
	watch: {
		epumpSlideLoaded: function(val) {
			if(val === true) {
				//im programatically clicking the buttons so that the framestack loads and caches properly
				this.$refs.epumpUserInterface.click();
				this.$refs.epumpUserInterface2.click();
				this.$refs.epumpUserInterface3.click();
				this.$refs.epumpUserInterface4.click();
				this.$refs.epumpUserInterface5.click();
				this.$refs.epumpUserInterface.click();
			}
		},
		joeyPumpChecked: function(val) {
			let vm = this;
			let buttons = document.querySelectorAll('.features__list .btn');
			buttons.forEach((button) => {
				button.classList.remove('active');
			});
			vm.removeAllHotspotActiveClasses();
			if(val === false) {
				setTimeout(() => {
					this.$refs.epumpUserInterface.classList.add('active');
					$(".joey").jsMovie("gotoFrame",52);
					let $epump = $('.epump');
					vm.activeButton = "User interface";
					$epump.jsMovie(epumpAnimation);
					$epump.jsMovie("gotoFrame",1);
					$epump.on('playing', () => {
						vm.removeAllHotspotActiveClasses();
					});
					$epump.on('pause', () => {
						let currentFrame = $('.epump').data('currentFrame').data('frame');

						//first one
						if(currentFrame === 1) {
							vm.queryActiveHotspots('features__epump-hs-ui');
						}
						//second one
						if(currentFrame === 13) {
							vm.queryActiveHotspots('features__epump-hs-erg');
						}
						//third one
						if(currentFrame === 44) {
							vm.queryActiveHotspots('features__epump-hs-feat');
						}
						//fourth one
						if(currentFrame === 24) {
							vm.queryActiveHotspots('features__epump-hs-serv');
						}
						//fifth one
						if(currentFrame === 49) {
							vm.queryActiveHotspots('features__epump-hs-efps');
						}
					});
				},20);
			} else {
				setTimeout(() => {
					this.$refs.joeyUserInterface.classList.add('active');
					$(".epump").jsMovie("gotoFrame",1);
					let $joey = $('.joey');
					$joey.jsMovie(joeyPumpAnimation);
					$joey.jsMovie("gotoFrame",52);
					$joey.on('playing', () => {
						vm.removeAllHotspotActiveClasses();
					});
					vm.activeButton = "User interface";
					$joey.on('pause', () => {
						let currentFrame = $joey.data('currentFrame').data('frame');
						//first one
						if(currentFrame === 52) {
							vm.queryActiveHotspots('features__joey-hs-ui');
						}
						//second one
						if(currentFrame === 10) {
							vm.queryActiveHotspots('features__joey-hs-erg');
						}
						//third one
						if(currentFrame === 44) {
							vm.queryActiveHotspots('features__joey-hs-feat');
						}
						//fourth one
						if(currentFrame === 21) {
							vm.queryActiveHotspots('features__joey-hs-serv');
						}
						//fifth one
						if(currentFrame === 48) {
							vm.queryActiveHotspots('features__joey-hs-efps');
						}
					});
				},20);
			}
		}
	},
	computed: {
		activeButtonComputed() {
			let activeButton = this.activeButton.toLowerCase();
			return activeButton.charAt(0).toUpperCase() + activeButton.slice(1);
		}
	},
	beforeDestroy() {
		const $el = $('.joey');
		$el.jsMovie("destroy");
		const $epump = $('.epump');
		$epump.jsMovie("destroy");
	}

}
</script>
