<template>
	<section class="section clinical-guidance fp-auto-height-responsive" id="expertClinicalGuidance">
		<div class="clinical-guidance__triangle z-lowest"></div>
		<div class="clinical-guidance__container position-relative z-low">
			<div class="col-lg-6 col-xl-6 clinical-guidance__teardrop-parent">
				<img src="@/assets/img/teardrop1.png" alt="" class="clinical-guidance__teardrop-1">
				<img src="@/assets/img/teardrop2.png" alt="" class="clinical-guidance__teardrop-2">
				<img src="@/assets/img/teardrop3.png" alt="" class="clinical-guidance__teardrop-3">
			</div>
			<div class="col-lg-6 col-xl-5">
				<div class="clinical-guidance__main-content">
					<div class="kicker">Expert clinical guidance</div>
					<h2 class="text-red balance-text">Enhanced care is just the beginning&mdash;get expert clinical guidance</h2>
					<p>The Kangaroo&trade; team offers enduring support to help ensure your patients and residents continually receive the highest standards of nutrition delivery. Grow your credibility with ongoing education and training designed to meet your outlined goals.</p>
					<ul>
						<li><strong>Continuing education:</strong> Our team will empower your staff with continuing education that supports compliance and quality rankings.</li>
						<li><strong>Protocol support:</strong> Develop protocols with our clinical experts to streamline the way nutrition and hydration is delivered.</li>
						<li><strong>Stay in compliance:</strong> Avoid enteral-feeding related citations while improving efficiencies in nursing time.</li>
					</ul>
					<button @click="$emit('slideToLink', $event, 7)" class="btn btn-primary btn-lg mt-4">Improve with Kangaroo&trade; solutions</button>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import * as balanceText from 'balance-text';
export default {
	name: "ExpertClinicalGuidance",
	props: ['audience', 'fullpage'],
	created() {
		balanceText();
	}
}
</script>
