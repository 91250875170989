<template>
	<div class="sidenav-bar">
		<div class="sidenav-bar__rotate-block" @mouseenter="$emit('sidebartoggled', $event)">
			<div class="sidenav-bar__section-number">{{currentSection}}</div>
			<div class="sidenav-bar__vertical-line">
				<div class="sidenav-bar__vertical-line-line"></div>
			</div>
			<div class="sidenav-bar__vertical-text" v-html="currentSectionTitle">{{currentSectionTitle}}</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "SidenavBar",
	props: {
		currentSection: String,
		currentSectionTitle: String
	}
}
</script>
