<template>
	<div class="mb-5">
		<h2 class="mt-3 balance-text">By your side across the care continuum</h2>
		<h3 class="balance-text">How can the Kangaroo™ solutions help you?</h3>

		<div class="mt-4">
			<p v-if="audience == 'acute'">
				Kangaroo&trade; systems help acute care teams reduce nursing times and costs while avoiding infections. Programmable, automated Feed/Flush technology delivers better patient care.
			</p>
			<p v-if="audience == 'postacute'">
				Kangaroo&trade; systems help post-acute care teams reduce resident rehospitalization rates while maintaining proper nutrition with stronger protocols for more optimal safety compliance. Reduced nursing times increase efficiency, driving positive financial impacts.
			</p>
			<p v-if="audience == 'athome'">
				Kangaroo&trade; systems help at-home patients easily manage their feeding and hydration needs without the hassles of manual flushing. Regain freedom and flexibility.
			</p>
		</div>
	</div>
</template>

<script>
import * as balanceText from 'balance-text';
export default {
	name: "AudienceFooterMessage",
	props: ['audience'],
	created() {
		balanceText();
	}
}
</script>
