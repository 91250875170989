<template>
	<section v-bind:class="['section section--intro', ((loaded) ? 'state-loaded' : '')]" ref="loadingScreen">
			<div v-bind:class="['loading-screen__box', ((loaded) ? 'state-loaded' : '')]"></div>
			<div v-bind:class="['loading-screen__animation', ((loaded) ? 'state-loaded' : '')]">
				<div><img src="@/assets/img/Kangaroo-run-loop-white.gif" width="150" height="auto" alt="Jumping Kangaroo"/></div>
				<h5>...Loading Better Enteral Feeding Care</h5>
			</div>
	</section>
</template>

<script>
export default {
	name: "FlushFeedIntro",
	props: ['loaded'],
	watch: {
		//when loaded remove the fixed state on body and remove the loading screen
		loaded: function (val) {
			if(val === true) {
				setTimeout(() => {
					this.$refs.loadingScreen.remove();
				}, 5000);
			}
		}
	}
}
</script>
