<template>
	<div class="footer__secondary mt-5">
		<div class="footer__phone h3"><span class="sr-only">Phone Number: </span> 800.964.5227</div>
		<div class="footer__copyright">&copy;2021 Cardinal Health Kangaroo&trade; All rights reserved.</div>
		<div class="footer__social h3">
			<a class="footer__social-link" href="https://www.facebook.com/CardinalHealthInc">
				<span class="icon-facebook"><span class="sr-only">Link to Facebook</span></span>
			</a>
			<a class="footer__social-link" href="https://twitter.com/cardinalhealth">
				<span class="icon-twitter"><span class="sr-only">Link to Twitter</span></span>
			</a>
			<a class="footer__social-link" href="https://www.youtube.com/cardinalhealth">
				<span class="icon-youtube-play"><span class="sr-only">Link to YouTube</span></span>
			</a>
		</div>
	</div>
</template>

<script>
export default {
	name: "SecondaryFooter"
}
</script>
