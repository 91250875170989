<template>
	<nav class="sidenav" ref="sidenav">
		<div class="sidenav__inner">
			<div class="sidenav__inner-col-1">

			</div>
			<div class="sidenav__inner-col-2 pr-3">
				<div class="sidenav__vertical-text">
					<img src="@/assets/img/cardinal-health-kangaroo_logo.png" alt="Cardinal Health Kangaroom Logo" class="w-100" @click="$emit('slideToLink', $event, 1)"/>
				</div>
				<div class="sidenav__vertical-flex">
					<ul class="sidenav__list">
						<li class="sidenav__item">
							<a href="#home" class="sidenav__link" @click="$emit('slideToLink', $event, 2)"><span>02</span> Who It's For</a>
						</li>
						<li class="sidenav__item">
							<a href="#features" class="sidenav__link" @click="$emit('slideToLink', $event, 3)"><span>03</span> Features</a>
						</li>
						<li class="sidenav__item">
							<a href="#howCanWeHelp" class="sidenav__link" @click="$emit('slideToLink', $event, 4)"><span>04</span> How We Can Help</a>
						</li>
						<li class="sidenav__item">
							<a href="#testimonials" class="sidenav__link" @click="$emit('slideToLink', $event, 5)"><span>05</span> Expert Clinical Guidance</a>
						</li>
						<li class="sidenav__item">
							<a href="#ourSystem" class="sidenav__link" @click="$emit('slideToLink', $event, 6)"><span>06</span> Our System</a>
						</li>
						<li class="sidenav__item">
							<a href="#" class="btn btn-lg btn-primary order-now-button" @click="$emit('slideToLink', $event, 7)">Order Now</a>
						</li>
					</ul>
					<div class="sidenav__more">
						<h4 class="mt-4">800.964.5227</h4>
						<div>
							<a class="sidenav__social-link" href="https://www.facebook.com/CardinalHealthInc">
								<span class="icon-facebook"><span class="sr-only">Facebook Link</span></span>
							</a>
							<a class="sidenav__social-link" href="https://twitter.com/cardinalhealth">
								<span class="icon-twitter"><span class="sr-only">Twitter Link</span></span>
							</a>
							<a class="sidenav__social-link" href="https://www.youtube.com/cardinalhealth">
								<span class="icon-youtube-play"><span class="sr-only">YouTube Link</span></span>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</nav>
</template>

<script>
export default {
	name: "Sidenav",
}
</script>
