<template>
	<section class="section home fp-auto-height-responsive" id="home">
		<div class="home__triangle z-lowest"></div>
		<div class="z-low position-relative">
			<div class="home__container">
				<div class="col-md-6 order-md-2 home__hero-wrap">
					<transition
						name="custom-classes-transition"
						enter-active-class="hero-enter"
						leave-active-class="hero-leave">
						<img
							:src="imgSrc"
							:key="imgSrc"
							ref="heroImage"
							class="home__hero" title="Care Workers" alt="HealthCare Worker">
					</transition>
				</div>
				<div class="col-md-6 col-lg-5 offset-lg-1 order-md-1">
					<img src="@/assets/img/cardinal-health-kangaroo_logo.png" class="home__logo" alt="Cardinal Health Kangaroo Logo">
					<div class="home__main-content">
						<h2 class="h1 text-nowrap text-red">By your side</h2>
						<h3 class="h2 thin">with a clear look at hydration</h3>
						<p><strong>Your patients &amp; residents deserve the best care. Your teams deserve the best technology.</strong></p>
						<p>Cardinal Health Kangaroo&trade; Nutritional Delivery Products safely nourish and automatically hydrate for a more optimal enteral feeding experience. Programmable Kangaroo&trade; Feed/Flush technology automatically hydrates for better patient and resident care&ndash;all while maintaining clear tubing lines, improving pump function and reducing costs to your facility.</p>
						<div class="d-flex align-items-center bounceArrow home__bounce-arrow" @click="moveDown">
							<span><span class="icon-chevron-down bounceArrow"></span></span>
							<span class="h3 text-red">Scroll for streamlined care</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	name: "Home",
	props: ['isReady', 'audience', 'fullpage', 'imgSrc'],
	data: () => {
		return {
			transitionName: 'fade'
		}
	},
	methods: {
		moveDown() {
			this.fullpage.api.moveSectionDown();
		}
	}
}
</script>
